import { Button, Image, message, Select, Spin } from 'antd';
import React, { Component } from 'react';
import { Link as I18Link } from 'react-router-i18n';
import { connect } from 'react-redux';
// import share from '../../assets/share.svg';
import { addProductToCart, editProduct, removeProductToCart } from '../../redux/cart/cart.action';
import './ProductCard.scss';
import { PlusOutlined, MinusOutlined, DeleteFilled, LoadingOutlined, HeartTwoTone, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { addProductUserCart, favProductUserCart, fetchAltsProduct, removeProductUserCart, updateProductUserCart } from '../../network/product.network';
import { fallbackImg } from '../../constants/constants';
import localization from '../../Localization/Localization.json';
import { withRouter } from 'react-router-dom';
import del_icon from '../../assets/img/del.svg';
import BranchModal from '../BranchModal/BranchModal';
import AlternativeModal from '../AlternativeModal/AlternativeModal';
const { Option } = Select;
class ProductCard extends Component {
  state = {
    isCardAdded: false,
    card_amount: null,
    btnLoading: false,
    updateLoading: false,
    openBranch: false,
    openAlternative: false,
    branchModalVisible: false,
    altModalVisible: false,
    card: null,
    alternatives: [],
  };

  componentDidMount() {
    const { card } = this.props;
    const quantityIdxActive = card?.inCart && card?.quantities.indexOf(card?.inCart?.quantity);
    const activeCutting = card?.selectedCuttingType ? card?.selectedCuttingType : card.cuttingTypes.length !== 0 ? card.cuttingTypes[0] : null;
    this.setState(
      {
        card,
        activeCutting,
        card_amount: {
          quantities: card.quantities,
          inCart: card.inCart,
          quantitiesIndex: quantityIdxActive || 0,
        },
      },
      () => {
        setTimeout(() => {
          this.checkProductIncart();
        }, 400);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // if(JSON.stringify(this.props.card) !== JSON.stringify(prevProps.card)){
    if (this.props.card.id !== prevProps.card.id) {
      const { card } = this.props;
      const quantityIdxActive = card?.inCart && card?.quantities.indexOf(card?.inCart?.quantity);
      this.setState(
        {
          card,
          activeCutting: card.cuttingTypes.length !== 0 ? card.cuttingTypes[0] : null,
          card_amount: {
            quantities: card.quantities,
            inCart: card.inCart,
            quantitiesIndex: quantityIdxActive || 0,
          },
        },
        () => {
          setTimeout(() => {
            this.checkProductIncart();
          }, 400);
        }
      );
    }
  }

  checkProductIncart = () => {
    const { card } = this.state;
    if (card.inCart) {
      this.setState({ isCardAdded: true });
      const isProductExistInStorage = this.props.cartItems.some(product => product.id === card.id);
      if (!isProductExistInStorage) {
        this.props.dispatch(addProductToCart({ ...card }));
      }
    } else {
      this.setState({ isCardAdded: false });
      const isProductExistInStorage = this.props.cartItems.some(product => product.id === card.id);
      isProductExistInStorage && this.setState({ isCardAdded: true });
    }
  };

  handelAddProductToCart = product => {
    const { currentUser } = this.props;

    if (!currentUser) {
      message.warning(localization.pleaseloginfirst[this.props.currentLang]);
      this.props.history.push('/login');
      return null;
    }
    const { card_amount, card, activeCutting } = this.state;
    // if (!this.props.currentBranch) {
    //   this.setState({ openBranch: true, branchModalVisible: true });
    //   return false;
    // }
    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append('productId', product.id);
    body.append('quantity', product.quantities[card_amount?.quantitiesIndex || 0]);
    if (activeCutting?.id) {
      body.append('cuttingTypeId', activeCutting?.id);
    }
    addProductUserCart(
      body,
      res => {
        this.setState({ btnLoading: false });

        if (res.data.code === 200) {
          message.success(res.data.message);
          let newCard = { ...card };
          newCard.inCart = {
            itemId: res.data.data.item.itemId,
            quantity: res.data.data.item.quantity,
          };
          this.setState({
            isCardAdded: true,
            btnLoading: false,
            card: newCard,
          });
          this.props.dispatch(
            addProductToCart({
              ...product,
              inCart: {
                itemId: res.data.data.item.itemId,
                quantity: res.data.data.item.quantity,
              },
              product_cart_count: card_amount?.quantitiesIndex,
            })
          );
        }
        // else if(res.data.code === 101){
        //   this.setState({openBranch : true, branchModalVisible : true})
        // }
      },
      error => {
        this.setState({ btnLoading: false });
        console.log(error);
      }
    );
  };

  handelUpdateProduct = controlType => {
    const { card_amount, card, activeCutting } = this.state;
    this.setState({ updateLoading: true });

    let body = new FormData();
    body.append('itemId', card?.inCart?.itemId);
    if (activeCutting?.id) {
      body.append('cuttingTypeId', activeCutting?.id);
    }
    body.append('quantity', card?.quantities[controlType === 'inc' ? card_amount?.quantitiesIndex + 1 : card_amount?.quantitiesIndex - 1]);
    updateProductUserCart(
      body,
      res => {
        this.setState({ updateLoading: false });
        if (res.data.code === 200) {
          message.success(localization.productupdatedsuccessfully[this.props.currentLang]);
          const updatedCardData = res.data.data.item;

          if (this.props.showCartCard) {
            if (!this.props.showFavCard) {
              this.props.dispatch(editProduct(updatedCardData));
            }
          }
          this.setState({
            card: {
              ...card,
              ...updatedCardData,
            },
          });
          controlType === 'inc'
            ? this.setState({
                card_amount: {
                  ...card_amount,
                  quantitiesIndex: +card_amount.quantitiesIndex + 1,
                },
              })
            : this.setState({
                card_amount: {
                  ...card_amount,
                  quantitiesIndex: +card_amount.quantitiesIndex - 1,
                },
              });
        }
      },
      error => {
        this.setState({ updateLoading: false });
        console.log(error);
      }
    );
  };

  handelDltProduct = () => {
    const { card, inCart } = this.state;
    this.setState({ updateLoading: true });
    let body = new FormData();
    body.append('itemId', card?.inCart?.itemId);
    removeProductUserCart(
      body,
      res => {
        this.setState({ updateLoading: false });
        if (res.data.code === 200) {
          this.setState({ isCardAdded: false });
          if (this.props.showCartCard) {
            this.props.removeItemFromList(card.id);
          }
          this.props.dispatch(removeProductToCart(card.id));
          message.success(res.data.message);
        }
      },
      error => {
        this.setState({ updateLoading: false });
        console.log(error);
      }
    );
  };

  handelFavProduct = () => {
    const { card } = this.state;
    if (!this.props.currentUser) {
      message.warning(localization.pleaseloginfirst[this.props.currentLang]);
      return false;
    }
    this.setState({ updateLoading: true });
    let body = new FormData();
    body.append('product', +card?.id);
    favProductUserCart(
      body,
      res => {
        this.setState({ updateLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          if (this.props.showFavCard) {
            this.props.removeItemFromfav(card.id);
          }
          this.setState({ card: { ...card, isFavorite: res.data.item } });
        }
      },
      error => {
        this.setState({ updateLoading: false });
        console.log(error);
      }
    );
  };

  handleChangeProductAmount = value => {
    const { card, card_amount, activeCutting, isCardAdded } = this.state;
    const quantityIdxActive = card?.quantities.indexOf(value);
    if (isCardAdded) {
      this.setState({ updateLoading: true });
      let body = new FormData();
      body.append('itemId', card?.inCart?.itemId);
      body.append('quantity', card?.quantities[quantityIdxActive]);
      if (activeCutting?.id) {
        body.append('cuttingTypeId', activeCutting?.id);
      }
      updateProductUserCart(
        body,
        res => {
          this.setState({ updateLoading: false });
          if (res.data.code === 200) {
            message.success(localization.productupdatedsuccessfully[this.props.currentLang]);
            this.setState({
              card_amount: {
                ...card_amount,
                quantitiesIndex: +quantityIdxActive,
              },
            });
          }
        },
        error => {
          this.setState({ updateLoading: false });
          console.log(error);
        }
      );
    } else {
      this.setState({
        card_amount: {
          ...card_amount,
          quantitiesIndex: +quantityIdxActive,
        },
      });
    }
  };

  handelShowAlt = card => {
    this.setState({ btnLoading: true, openAlternative: true }, () => {
      fetchAltsProduct(
        card.id,
        res => {
          this.setState({
            alternatives: res.data.data.alternatives,
            btnLoading: false,
            altModalVisible: true,
          });
        },
        error => {
          console.log(error);
          this.setState({ btnLoading: true });
        }
      );
    });
  };

  handleChangeProductType = activeCuttingVal => {
    const { card, activeCutting, isCardAdded } = this.state;
    if (isCardAdded) {
      this.setState({
        updateLoading: true,
        activeCutting: { ...this.state.activeCutting, id: activeCuttingVal },
      });
      let body = new FormData();
      body.append('itemId', card?.inCart?.itemId);
      body.append('quantity', card?.inCart?.quantity);
      if (activeCutting?.id) {
        body.append('cuttingTypeId', activeCuttingVal);
      }
      updateProductUserCart(
        body,
        res => {
          this.setState({ updateLoading: false });
          if (res.data.code === 200) {
            message.success(localization.productupdatedsuccessfully[this.props.currentLang]);
          }
        },
        error => {
          this.setState({ updateLoading: false });
          console.log(error);
        }
      );
    } else {
      this.setState({
        activeCutting: { ...this.state.activeCutting, id: activeCuttingVal },
      });
    }
  };

  render() {
    const { discount, showCartCard, currentLang, showFavCard } = this.props;
    const {
      isCardAdded,
      altModalVisible,
      openAlternative,
      alternatives,
      card_amount,
      btnLoading,
      card,
      updateLoading,
      activeCutting,
      branchModalVisible,
      openBranch,
    } = this.state;
    return (
      <>
        {showCartCard ? (
          <div className={`cart-product-card ${card?.outOfStock === 1 && 'alt-product'}`}>
            {openBranch && <BranchModal closeModal={() => this.setState({ branchModalVisible: false })} branchModalVisible={branchModalVisible} />}
            {openAlternative && (
              <AlternativeModal alternatives={alternatives} closeModal={() => this.setState({ altModalVisible: false })} altModalVisible={altModalVisible} />
            )}
            <div
              className="product-img"
              onClick={() =>
                this.props.history.push(
                  `/${this.props.currentLang}/product/${card?.id}/${card?.finalPrice}/${card?.image?.split('/').pop() || 'LE03137'}/${card?.productName
                    ?.replace('/', '')
                    .replace('%', '')}`
                )
              }>
              {/* <Link>  */}
              <Image src={card?.image_link || card?.image || fallbackImg} fallback={fallbackImg} preview={false} />
              {/* </Link> */}
            </div>
            <div className="cart-product--content">
              <div className="product-info">
                <h6 className="product-name">{card?.productName || card?.name}</h6>
                <div className="price-holder">
                  {/* <p className="price-after ddd">
                    {card?.finalPrice} {localization.AED[currentLang]}
                  </p>
                  {card?.oldPrice !== '0' && (
                    <p className="price-before">
                      {card?.oldPrice} {localization.AED[currentLang]}
                    </p>
                  )} */}
                  {card?.finalPrice * card?.quantity > card?.amount ? (
                    <>
                      <p className="price-after">
                        {card?.amount} {localization.AED[currentLang]}
                      </p>

                      <p className="price-before">
                        {card?.finalPrice * card?.quantity} {localization.AED[currentLang]}
                      </p>
                    </>
                  ) : (
                    <p className="price-after">
                      {card?.amount} {localization.AED[currentLang]}
                    </p>
                  )}
                </div>
              </div>

              <div className="product-control-holder">
                {showFavCard ? (
                  <div className="card-control">
                    {isCardAdded ? (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={updateLoading}>
                        <div className="card-count-holder">
                          <Button onClick={() => this.handelUpdateProduct('dec')} disabled={card_amount?.quantitiesIndex === 0}>
                            <MinusOutlined />
                          </Button>
                          <p className="product-count">{isCardAdded ? card_amount?.quantities[card_amount?.quantitiesIndex] : null}</p>
                          <Button onClick={() => this.handelUpdateProduct('inc')} disabled={card_amount?.quantitiesIndex === card?.quantities?.length - 1}>
                            <PlusOutlined />
                          </Button>
                        </div>
                      </Spin>
                    ) : (
                      <Button
                        loading={btnLoading}
                        type="primary"
                        size="large"
                        block
                        ghost
                        style={{ width: 155 }}
                        onClick={() => this.handelAddProductToCart(card)}>
                        {localization.Addtocart[currentLang]}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={updateLoading}>
                    <div className="card-count-holder">
                      <Button onClick={() => this.handelUpdateProduct('dec')} disabled={card_amount?.quantitiesIndex === 0}>
                        <MinusOutlined />
                      </Button>
                      <p className="product-count">{isCardAdded ? card_amount?.quantities[card_amount?.quantitiesIndex] : null}</p>
                      <Button onClick={() => this.handelUpdateProduct('inc')} disabled={card_amount?.quantitiesIndex === card?.quantities?.length - 1}>
                        <PlusOutlined />
                      </Button>
                    </div>
                  </Spin>
                )}
                {showFavCard ? (
                  <div className="product-fav-dlt fav-card-share">
                    {card?.isFavorite && <DeleteFilled className="dlt-btn" onClick={() => this.handelFavProduct(card)} />}
                  </div>
                ) : (
                  <div className="product-fav-dlt" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <DeleteFilled
                    className="dlt-btn"
                    onClick={() => this.handelDltProduct(card)}
                  /> */}
                    <img src={del_icon} alt="delete" className="dlt-btn" style={{ cursor: 'pointer' }} onClick={() => this.handelDltProduct(card)} />
                    {card?.isFavorite ? (
                      <HeartFilled style={{ color: '#CC0A33' }} onClick={() => this.handelFavProduct(card)} />
                    ) : (
                      <HeartOutlined
                        onClick={() => this.handelFavProduct(card)}
                        // twoToneColor={'#00672C'}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={`product-card-wrapper ${card?.outOfStock === 1 ? 'alt-product' : ''}`}>
            {openBranch && <BranchModal closeModal={() => this.setState({ branchModalVisible: false })} branchModalVisible={branchModalVisible} />}
            {openAlternative && (
              <AlternativeModal alternatives={alternatives} closeModal={() => this.setState({ altModalVisible: false })} altModalVisible={altModalVisible} />
            )}
            {card?.outOfStock === 1 && <div className="out-stock-holder">{localization.Outofstock[currentLang]}</div>}
            {/* {card?.discount !== '0' && <p className="product-discount">Reduced price</p>} */}
            {card?.isSpecialOffer && <p className="special-offer">{card?.specialOfferDesc}</p>}
            <div className="product-fav-icon-holder" style={{ top: card?.isSpecialOffer ? '48px' : '20px' }}>
              {card?.isFavorite ? (
                <HeartFilled style={{ color: '#CC0A33' }} onClick={() => this.handelFavProduct(card)} />
              ) : (
                <HeartOutlined
                  onClick={() => this.handelFavProduct(card)}
                  // twoToneColor={'#00672C'}
                />
              )}
            </div>

            <I18Link
              className="product-img"
              to={{
                pathname: `/product/${card?.id}/${card?.finalPrice}/${card?.image?.split('/').pop() || 'LE03137'}/${card?.name
                  ?.replace('/', '')
                  .replace('%', '')}`,
              }}>
              <Image src={card?.image_link || card?.image || fallbackImg} fallback={fallbackImg} preview={false} />
            </I18Link>
            <div className="product-type">
              {/* <Select
                value={card?.quantities[card_amount.quantitiesIndex]}
                style={{ width: 80 }}
                onChange={this.handleChangeProductAmount}
                className="select-product-amount select-product"
              >
                {card?.quantities.map((quantity) => (
                  <Option value={quantity}>{quantity}</Option>
                ))}
              </Select> */}
              {card?.cuttingTypes.length > 0 && (
                <Select value={activeCutting?.id} style={{ width: 100 }} onChange={this.handleChangeProductType} className="select-product-type select-product">
                  {card?.cuttingTypes.map(cutting => (
                    <Option value={cutting.id}>{cutting.name}</Option>
                  ))}
                </Select>
              )}
            </div>
            <h6 className="product-name">{card?.name || card?.title}</h6>
            <div className="price-holder">
              <p className="price-after">
                {card?.finalPrice} {localization.AED[currentLang]}
              </p>
              {card?.oldPrice !== '0' && (
                <p className="price-before">
                  {card?.oldPrice} {localization.AED[currentLang]}
                </p>
              )}
            </div>
            <div className="card-control">
              {card?.outOfStock === 1 ? (
                <Button loading={btnLoading} type="primary" size="large" block onClick={() => this.handelShowAlt(card)}>
                  {localization.showalternatives[currentLang]}
                </Button>
              ) : isCardAdded ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={updateLoading}>
                  <div className="card-count-holder">
                    {/* <div className="card-count-info">
                      <p className="item-count"> 
                      {isCardAdded
                            ? card_amount?.quantities[card_amount?.quantitiesIndex]
                            : null}
                            {' '}
                       {localization.item[currentLang]} </p> 
                      <p className="items-price">
                        {(+card?.finalPrice * +card_amount?.quantities[card_amount?.quantitiesIndex]).toFixed(2)}{' '}
                        {localization.AED[currentLang]}
                      </p>
                    </div> */}
                    <div className="card-count-btns">
                      {/* <img
                        src={del_icon}
                        alt="delete"
                        style={{ cursor: 'pointer' }} 
                        onClick={() => this.handelDltProduct(card)}
                      /> */}
                      {/* <DeleteFilled
                      className="dlt-btn"
                      onClick={() => this.handelDltProduct(card)}
                    /> */}
                      <div className="btns-wrapper">
                        {card_amount.quantitiesIndex === 0 ? (
                          <img src={del_icon} alt="delete" style={{ cursor: 'pointer' }} onClick={() => this.handelDltProduct(card)} />
                        ) : (
                          <Button onClick={() => this.handelUpdateProduct('dec')} disabled={card_amount?.quantitiesIndex === 0}>
                            <MinusOutlined />
                          </Button>
                        )}
                        <p className="product-count">{isCardAdded ? card_amount?.quantities[card_amount?.quantitiesIndex] : null}</p>
                        <Button onClick={() => this.handelUpdateProduct('inc')} disabled={card_amount?.quantitiesIndex === card?.quantities?.length - 1}>
                          <PlusOutlined />
                        </Button>
                      </div>
                    </div>
                  </div>{' '}
                </Spin>
              ) : (
                <Button loading={btnLoading} type="primary" size="large" block onClick={() => this.handelAddProductToCart(card)}>
                  {localization.Addtocart[currentLang]}
                </Button>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

const ProductCardWithRouter = withRouter(ProductCard);

const mapStateToProps = state => ({
  cartItems: state.cart.cartItems,
  currentLang: state.user.currentLang,
  // currentBranch: state.user.currentBranch,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(ProductCardWithRouter);
