import { Breadcrumb, Button, message, Modal } from 'antd';
import React, { Component } from 'react';
import { Link as I18Link } from 'react-router-i18n';
import { connect } from 'react-redux';
import CartProducts from '../../components/CartProducts/CartProducts';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import localization from '../../Localization/Localization.json';

import './cart.scss';
import AddressModal from '../../components/AddressModal/AddressModal';
import { applyPromoCode, applyRemovePromoCode, EmptyCart } from '../../network/cart.network';
import { withRouter } from 'react-router-dom';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import { fetchCart } from '../../network/product.network';
// import { setAddressUser, setPaymentUser } from '../../redux/user/user.action';
import SelectPayMethod from '../../components/SelectPayMethod/SelectPayMethod';
import { addProductToCart, clearCart } from '../../redux/cart/cart.action';
import del_icon from '../../assets/img/del.svg';
import { Helmet } from 'react-helmet';
import meta from '../../Localization/meta.json';

const { confirm } = Modal;
class Cart extends Component {
  state = {
    showModalVisible: false,
    showPayModal: false,
    codeLoading: false,
    Promo_code: '',
    cartItems: [],
    isLoading: true,
    cartInfo: null,
  };

  componentDidMount() {
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
    fetchCart(
      res => {
        if (res.data.data.cart?.totals?.promoCode) {
          this.setState({ Promo_code: res.data.data.cart?.totals?.promoCode });
        }
        // this.props.dispatch(setAddressUser(res.data.data.cart?.address));
        // this.props.dispatch(setPaymentUser(res.data.data.cart?.paymentType));
        // if (!this.props.currentPayment) {
        //   if (res.data.data.cart?.paymentType) {
        //     this.props.dispatch(
        //       setPaymentUser(res.data.data.cart?.paymentType)
        //     );
        //   }
        // }

        this.props.dispatch(clearCart());
        setTimeout(() => {
          res.data.data.cart.items.forEach(ele => {
            const isProductExistInStorage = this.props.cartItems?.some(product => +product.id === +ele.productId);
            if (!isProductExistInStorage) {
              this.props.dispatch(
                addProductToCart({
                  ...ele,
                  id: +ele.productId,
                  productId: +ele.productId,
                  inCart: {
                    itemId: +ele.itemId,
                    quantity: +ele.quantity,
                  },
                })
              );
            }
          });
        }, 300);

        this.setState({
          cartInfo: res.data.data.cart,
          cartItems: res.data.data.cart.items.map(item => ({
            ...item,
            productId: +item.productId,
            id: +item.productId,
            inCart: {
              itemId: +item.itemId,
              quantity: item.quantity,
            },
          })),
          isLoading: false,
        });
      },
      error => {
        this.setState({ isLoading: false });
        console.log(error);
      }
    );
  }

  removeCardInCard = id => {
    fetchCart(
      res => {
        // this.props.dispatch(setAddressUser(res.data.data.cart?.address));
        // this.props.dispatch(setPaymentUser(res.data.data.cart?.paymentType));
        // if (!this.props.currentPayment) {
        //   if (res.data.data.cart?.paymentType) {
        //     this.props.dispatch(
        //       setPaymentUser(res.data.data.cart?.paymentType)
        //     );
        //   }
        // }

        this.props.dispatch(clearCart());
        setTimeout(() => {
          res.data.data.cart.items.forEach(ele => {
            const isProductExistInStorage = this.props.cartItems?.some(product => +product.id === +ele.productId);
            if (!isProductExistInStorage) {
              this.props.dispatch(
                addProductToCart({
                  ...ele,
                  id: +ele.productId,
                  inCart: {
                    itemId: +ele.itemId,
                    quantity: +ele.quantity,
                  },
                })
              );
            }
          });
        }, 300);

        this.setState({
          cartInfo: res.data.data.cart,
          cartItems: res.data.data.cart.items.map(item => ({
            ...item,
            id: +item.productId,
            inCart: {
              itemId: +item.itemId,
              quantity: item.quantity,
            },
          })),
          isLoading: false,
        });
      },
      error => {
        this.setState({ isLoading: false });
        console.log(error);
      }
    );
  };

  handelPromoCode = () => {
    const { Promo_code, cartInfo } = this.state;
    this.setState({ codeLoading: true });
    let body = new FormData();
    body.append('promocode', Promo_code);
    applyPromoCode(
      body,
      res => {
        this.setState({ codeLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.setState({
            cartInfo: {
              ...cartInfo,
              totals: { ...cartInfo.totals, promoCode: Promo_code },
            },
          });
        }
      },
      error => {
        this.setState({ codeLoading: false });
        console.log(error);
      }
    );
  };

  handelRemovePromoCode = () => {
    const { cartInfo } = this.state;
    this.setState({ codeLoading: true });
    let body = new FormData();
    body.append('promocode', cartInfo?.totals?.promoCode);
    applyRemovePromoCode(
      body,
      res => {
        this.setState({ codeLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.setState({
            cartInfo: {
              ...cartInfo,
              totals: { ...cartInfo.totals, promoCode: null },
            },
          });
        }
      },
      error => {
        this.setState({ codeLoading: false });
        console.log(error);
      }
    );
  };

  handelCheckoutRoute = () => {
    if (!this.props.currentUser) {
      this.props.history.push('/login');
      return false;
    }
    if (this.state.cartItems.length === 0) {
      message.warning(localization.YourCartEmpty[this.props.currentLang]);
    }
    // else if (this.handelTotalPrice() < this.state?.cartInfo?.minimumAmount) {
    //   message.warning(
    //     localization.cartvalue[this.props.currentLang] +
    //       this.state.cartInfo?.minimumAmount +
    //       localization.AED[this.props.currentLang]
    //   );
    // }
    else if (!this.props.currentAddress) {
      message.warning(localization.pleaseselectaddresscontinue[this.props.currentLang]);
      this.setState({ showModalVisible: true });
    } else if (!this.props.currentPayment) {
      message.warning(localization.pleaseselectpaymentcontinue[this.props.currentLang]);
      this.setState({ showPayModal: true });
    } else {
      this.props.history.push(`/${this.props.currentLang}/checkout`);
    }
  };

  resetCart = () => {
    this.setState({ cartItems: [] });
  };

  setPaymentCallBack = Type => {
    const { cartInfo } = this.state;
    this.setState({
      cartInfo: {
        ...cartInfo,
        paymentType: Type,
        totals: { ...cartInfo.totals, paymentType: Type },
      },
    });
  };

  handelClearCart = () => {
    confirm({
      title: localization.deleteAllproductcart[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: localization.Yes[this.props.currentLang],
      okType: 'danger',
      cancelText: localization.Cancel[this.props.currentLang],
      onOk: () => {
        EmptyCart(
          res => {
            if (res.data.code === 200) {
              message.success(res.data.message);
              this.props.dispatch(clearCart());
              this.resetCart();
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      onCancel() {
        //   console.log('Cancel');
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // var eq = Object.toJSON(user1) == Object.toJSON(user2);
  }

  handelTotalPrice = () => {
    const { cartItemsProps } = this.props;
    const cartTotalPrice = cartItemsProps.reduce((acc, pro) => acc + pro.amount, 0);
    return cartTotalPrice.toFixed(2);
  };
  render() {
    const { showModalVisible, codeLoading, cartItems, isLoading, cartInfo, showPayModal } = this.state;
    const { currentLang, currentAddress, currentUser, cartItemsProps } = this.props;

    return (
      <div className="cart-page-wrapper">
        <Helmet>
          <title>{meta.KhairZamanCart[currentLang]}</title>
          <script>
            {`
              gtag('event', 'conversion', {'send_to' : 'AW-590585837/G4R6CPyC3KADEO2_zpkC'});
              `}
          </script>
        </Helmet>
        <Header />
        <AddressModal noSetAddressLocal ispopup closeModal={() => this.setState({ showModalVisible: false })} showModalVisible={showModalVisible} />
        <SelectPayMethod
          closeModal={() => this.setState({ showPayModal: false })}
          showModalVisible={showPayModal}
          currentLang={currentLang}
          localization={localization}
          setPaymentCallBack={Type => this.setPaymentCallBack(Type)}
        />

        <div className="container">
          <div className="cart-heade-bread">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>{localization.home[currentLang]}</I18Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{localization.cart[currentLang]}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="actions-wrapper">
              <Button type="primary" ghost icon={<ArrowLeftOutlined />} onClick={() => this.props.history.push('/')}>
                {localization.continueShopping[currentLang]}
              </Button>
              {this.state.cartItems.length !== 0 && (
                <Button type="default" danger icon={<img src={del_icon} alt="" width="24px" />} onClick={() => this.handelClearCart()}>
                  {localization.Removeall[currentLang]}
                </Button>
                // <p
                //   className="clear-cart-btn"
                //   onClick={() => this.handelClearCart()}
                // >
                //   <img src={del_icon} alt="" />
                //   {localization.Removeall[currentLang]}
                // </p>
              )}
            </div>
          </div>
          <div className="cart-content-wrapper">
            <div className="cart-main">
              {/* {this.state.cartItems.length !== 0 &&
                this.handelTotalPrice() < cartInfo?.minimumAmount && (
                  <div className="total-order-warning">
                    <p>
                      {localization.Thetotalcartvalue[currentLang]}{' '}
                      {cartInfo?.minimumAmount} {localization.AED[currentLang]}
                      <I18Link to={{ pathname: '/' }}>
                        {localization.Shopmore[currentLang]}
                      </I18Link>
                    </p>
                  </div>
                )} */}
              <CartProducts removeCardInCard={id => this.removeCardInCard(id)} isLoading={isLoading} cartItemsList={cartItems} />
            </div>
            <div className="cart-aside">
              <div className="cart-aside-content">
                <div className="total-price-holder">
                  <h5 className="total-title">{localization.Totalprice[currentLang]}</h5>
                  {/* {cartInfo?.totals?.totalBeforeDelivery &&
                    <p className="total-val">{cartInfo?.totals?.totalBeforeDelivery} {localization.AED[currentLang]}</p>
                  } */}
                  <p className="total-val">
                    {this.handelTotalPrice()} {localization.AED[currentLang]}
                  </p>
                </div>
                <div className="cart-aside-details">
                  <div className="cart-aside-card">
                    {/* <h6 className="cart-aside-card--title">{localization.Deliverto[currentLang]}</h6> */}
                    {currentAddress ? (
                      <div className="aside-card-row">
                        <p className="card-row-min-title">{localization.Address[currentLang]}</p>
                        <h4 className="card-row-title">{currentAddress.fullAddress}</h4>
                        <p className="card-row-btn" onClick={() => this.setState({ showModalVisible: true })}>
                          {localization.CHange[currentLang]}
                        </p>
                      </div>
                    ) : (
                      <div className="aside-card-row">
                        <p className="card-row-min-title">{localization.Address[currentLang]}</p>
                        <p
                          className="card-row-btn"
                          onClick={() => {
                            if (!this.props.currentUser) {
                              message.warning(localization.pleaseloginproceedcartorder[this.props.currentLang]);
                              return false;
                            }
                            this.setState({ showModalVisible: true });
                          }}>
                          {localization.selectAddress[currentLang]}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="cart-aside-card">
                    {/* <h6 className="cart-aside-card--title">{localization.Paywith[currentLang]}</h6>  */}

                    {cartInfo?.paymentType ? (
                      <div className="aside-card-row">
                        <p className="card-row-min-title">{localization.Payment[currentLang]}</p>
                        <h4 className="card-row-title">{cartInfo?.paymentType?.type}</h4>
                        <p className="card-row-btn" onClick={() => this.setState({ showPayModal: true })}>
                          {localization.CHange[currentLang]}
                        </p>
                      </div>
                    ) : (
                      <div className="aside-card-row">
                        <p className="card-row-min-title">{localization.Payment[currentLang]}</p>
                        <p
                          className="card-row-btn"
                          onClick={() => {
                            if (!this.props.currentUser) {
                              message.warning(localization.pleaseloginproceedcartorder[this.props.currentLang]);
                              return false;
                            }
                            this.setState({ showPayModal: true });
                          }}>
                          {localization.selectpaymentmethod[currentLang]}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div className="cart-aside-promo">
                  <div className="cart-aside-promo--header">
                      <h6 className="promo--title">{localization.Promocode[currentLang]}</h6>
                      {
                        cartInfo?.totals?.promoCode &&
                        <p className="promo--status">{localization.CodeApplied[currentLang]} <CheckOutlined /></p>
                      }
                  </div>
                  <div className="promo-apply">
                  <Input placeholder={localization.Entervalidpromocode[currentLang]} value={this.state.Promo_code} onChange={(e) => this.setState({Promo_code : e.target.value}) } /> 
                  {cartInfo?.totals?.promoCode ? 
                  <Button size="large" type="danger" onClick={this.handelRemovePromoCode} loading={codeLoading}>{localization.Remove[currentLang]}</Button>
                 :
                  <Button size="large" type="primary" onClick={this.handelPromoCode} loading={codeLoading}>{localization.Apply[currentLang]}</Button>
                  }
                  </div>
                </div> */}
                  <div className="aside-btns-holder">
                    <Button
                      size="large"
                      type="primary"
                      // disabled={!!!currentAddress || !!!currentPayment || !!!currentUser}
                      onClick={this.handelCheckoutRoute}
                      style={{
                        backgroundColor: '#00672C',
                        borderColor: '#00672C',
                      }}>
                      {this.props.currentAddress && this.props.currentPayment ? localization.checkout[currentLang] : localization.Continue[currentLang]}
                    </Button>
                    <Button size="large" type="primary" className="primary-btn-light">
                      <I18Link to={{ pathname: '/' }}>{localization.Back[currentLang]}</I18Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}
const CartWithRouter = withRouter(Cart);
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
  currentAddress: state.user.currentAddress,
  currentPayment: state.user.currentPayment,
  cartItemsProps: state.cart.cartItems,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(CartWithRouter);
