import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import '../../scss/pages/auth.scss';
import { resendVerificationCode, verifyCode } from '../../network/auth.network';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.action';
import { codeValidation, codeValidationAr } from '../../constants/constants';
import { clearCart } from '../../redux/cart/cart.action';
import localization from '../../Localization/Localization.json';
// import logo from '../../assets/img/Logo.png';
import logo from '../../assets/img/logogreen-croped.png';

import { MailOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import meta from '../../Localization/meta.json';

class PhoneVerify extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
      disableResetCode: false,
      tel: null,
    };
  }

  componentDidMount() {
    // if (this.props.location?.state?.user_phone) {
    //   var tel = this.props.location?.state?.user_phone;
    //   tel = '' + tel;
    //   var telsubstr = tel.substr(0, 3) + '****' + tel.substr(7);
    //   console.log(telsubstr);
    //   this.setState({ tel: telsubstr });
    // }
  }

  onFinish = values => {
    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append('user_id', this.props.location?.state.user_id);
    body.append('deviceType', 'Web');
    // body.append("deviceId", `${localStorage.getItem("deviceId")}`);
    body.append('code', values.Code);
    verifyCode(
      body,
      res => {
        this.setState({ btnLoading: false });

        if (res.data.code === 200) {
          localStorage.setItem('token', res.data.message);
          this.props.dispatch(setCurrentUser({ ...res.data.data.user, token: res.data.message }));
          this.props.dispatch(clearCart());
          setTimeout(() => {
            this.props.history.push(`/${this.props.currentLang}/`);
          }, 400);
        }
      },
      error => {
        console.log(error);
        this.setState({ btnLoading: false });
      }
    );
  };

  handelResetCode = () => {
    let body = new FormData();
    body.append('user_id', this.props.location.state.user_id);

    resendVerificationCode(
      body,
      res => {
        this.setState({ btnLoading: false });

        if (res.data.code === 200) {
          message.info(res.data.message);
          this.setState({ disableResetCode: true });
          setTimeout(() => {
            this.setState({ disableResetCode: false });
          }, 30000);
        }
      },
      error => {
        console.log(error);
        this.setState({ btnLoading: false });
      }
    );
  };

  render() {
    const { btnLoading, disableResetCode, tel } = this.state;
    const { currentLang } = this.props;
    console.log('props', this.props);
    return (
      <div>
        <Helmet>
          <title>{meta.KhairZamanVerification[currentLang]}</title>
        </Helmet>
        <div className="auth-wrapper">
          <div className="auth-form-content">
            <I18Link to={{ pathname: '/' }} className="logo-holder">
              <img src={logo} alt="logo" className="typ-new-logo" />
            </I18Link>
            <p className="form-welcom">{localization.Verifyaccount[currentLang]}</p>
            <p className="login-text">
              {localization.entercodesent[currentLang]} {tel && tel}
            </p>
            <Form name="basic" onFinish={this.onFinish}>
              <Form.Item
                label={localization.Code[currentLang]}
                name="Code"
                rules={currentLang === 'en' ? codeValidation : codeValidationAr}
                className="input-holder group-floating-label">
                <Input prefix={<UserOutlined className="site-form-item-icon" />} className="input-control" />
              </Form.Item>

              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" block loading={btnLoading}>
                  {localization.Verifyaccount[currentLang]}
                </Button>
              </Form.Item>
              <p className="user-text">
                {localization.Didntreceivecode[currentLang]}{' '}
                <a disabled={disableResetCode} onClick={this.handelResetCode}>
                  {' '}
                  {localization.Sendagain[currentLang]}{' '}
                </a>{' '}
              </p>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});
const PhoneVerifyWithRouter = withRouter(PhoneVerify);
export default connect(mapStateToProps)(PhoneVerifyWithRouter);
